import { Component } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';

import { StateService } from '../app/state.service';

import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    public state: StateService,
    public router: Router,
    private route: ActivatedRoute,
    private storage: Storage,
  ) { }

  async ngOnInit() {

    let term = await this.storage.get('term').then((res) => {
      return res;
    })
    let entity = await this.storage.get('entity').then((res) => {
      return res;
    })

    if (!entity._id) {
      this.state.GetData(term).then((res) => {
      })
    } else {
      this.state.Entity = entity;
    }

    // this.state.GetData().then((res) => {
    //
    // })
  }

}
