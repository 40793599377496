import { Injectable } from '@angular/core';
import axios from 'axios';

import { LoadingController, ToastController } from '@ionic/angular';

import { Storage } from '@ionic/storage';
import * as CryptoJS from 'crypto-js'

@Injectable({
  providedIn: 'root'
})
export class StateService {

  Entity: any = [];
  CEO: any = {
    SameCFO: false,
    SameSecretary: false,
    SameDirector: false,
  };
  CFO: any = {};
  Secretary: any = {};
  Directors: any = [];
  OtherPersons: any = [];
  isOtherPerson: any = false;
  Info: any = {};
  AddedInfo: any = {};

  isLoading: any = "";
  loading: any;
  Loading: Boolean = true;

  // URL = "https://efilebusiness-search-backend.herokuapp.com";
  URL = "https://5qgstng9il.execute-api.us-west-1.amazonaws.com/dev";
  // URL = "http://localhost:3000";

  constructor(
    public loadingController: LoadingController,
    public toastController: ToastController,
    private storage: Storage,
  ) { }

  async decryptObject(data) {
    let getoriginaltext = await data.toString();
    var decrypted = CryptoJS.AES.decrypt(getoriginaltext, '=A[W%Yz<joV*3_Y)')
    data = CryptoJS.enc.Utf8.stringify(decrypted)
    let object = JSON.parse(data)
    return object
  }

  GetData(term) {
    return new Promise(resolve => {
      axios.get(this.URL + "/searchByNumber?term=" + term).then(async (res) => {
        this.Entity = await this.decryptObject(res.data)
        this.Entity.EntityName = this.Entity.EntityName.split("\n").pop();
        // if (this.Entity.CEOfirstName) {
        //   this.CEO.FirstName = this.Entity.CEOfirstName.trim()
        // }
        // if (this.Entity.CEOmiddlename) {
        //   this.CEO.MiddleName = this.Entity.CEOmiddlename.trim()
        // }
        // if (this.Entity.CEOlastname) {
        //   this.CEO.LastName = this.Entity.CEOlastname.trim()
        // }

        this.storage.set("entity", this.Entity).then((res) => {
        })
        if (res) {
          this.Loading = false;
          resolve(true)
        } else {
          resolve(false)
        }
      })
    })
  }

  AddData() {
    return new Promise(resolve => {

      let payload = {
        EntityName: this.Entity.EntityName,
        EntityNumber: this.Entity.EntityNumber,
        Jurisdiction: this.Entity.Jurisdiction,
        RegistrationDate: this.Entity.RegistrationDate,
        Status: this.Entity.Status,
        AgentForServiceOfProcessName: this.Entity.AgentForServiceOfProcessName,
        EntityType: this.Entity.EntityType,
        AgentForServiceOfProcessAddress: this.Entity.AgentForServiceOfProcessAddress,
        EntityAddress: this.Entity.EntityAddress,
        EntityMailingAddress: this.Entity.EntityMailingAddress || this.Entity.EntityAddress,
        Persons: {
          CEO: this.CEO,
          CFO: this.CFO,
          Secretary: this.Secretary,
          Directors: this.Directors,
          OtherPersons: this.OtherPersons
        }
      }



      axios.post(this.URL + "/data/addinformation", payload).then((res) => {
        if (res) {
          this.AddedInfo = res.data.addinformation;
          resolve(true)
        } else {
          resolve(false)
        }
      })
    })
  }

  async presentToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 3000,
      keyboardClose: true,
      position: 'top',
    });
    toast.present();
  }

  async presentLoading(msg) {
    this.isLoading = true;
    this.loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: msg,
    });
    this.loading.present().then((res) => {
      if (!this.isLoading) {
        this.isLoading = false;
        this.loading.dismiss();
      }
    });
  }

  HideLoading() {
    this.isLoading = false;
    this.loading.dismiss();
  }
}
